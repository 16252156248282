<template>
  <div id="app_div">
      <div class="nav_div">
          <img src="../../assets/image/preferential_title.png" alt="">
      </div>
      <div class="app_body_div" v-for="(t,i) in tableData" :key="i" >
           <div class="title_nav">
                <img v-if="t.brand=='奥迪'" src="../../assets/image/audi.png" alt="">
                <img v-else-if="t.brand=='奔驰'" src="../../assets/image/benzi.png" alt="">
                <img v-else-if="t.brand=='保时捷'" src="../../assets/image/porsche.png" alt="">
            </div>
          <div class="item_div" v-for="(item,index) in t.value"  @click="tart_url(item.url)" :key="index">
              <div class="item_left">
                  <div class="item_name">{{item.series+" "+item.model+" "+item.name}}</div>
                  <div class="item_daihao">代号{{item.no+' OE:'+item.oe}}</div>
                  <div class="item_price">4S店价:￥{{item.price4s}}</div>
              </div>
              <div class="item_right">
                  <div class="right_price">
                      平台零售价:￥{{item.price}}
                  </div>
                  <div class="arrow">
                      <div class="quanhou_div">
                          <div>
                              ￥
                            <br>
                            券后价
                          </div>  
                          <div  style="text-align:center;">
                            <svg t="1632991464109" class="icon" viewBox="0 0 1025 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4371" width="20" height="20"><path d="M691.49184 550.48704 691.49184 49.9712 338.41152 49.9712 338.41152 550.48704 171.47904 550.48704 517.62176 998.4 863.75936 550.48704Z" p-id="4372" fill="#1afa29"></path></svg>
                            <br>
                            <span style="font-size:24px;font-weight:600;color:rgb(26, 250, 41);">{{item.lessPrice}}</span>
                         </div>   
                        <div class="liji_div">
                            立即
                            <br>
                            领券
                        </div> 
                      </div>
                  </div>
              </div>
              <hr align="center" width="96%">
          </div>
      </div>
  </div>
</template>

<script>
import wx from 'weixin-js-sdk';
import {userInfo} from '../../store/provider'
export default {
    data(){
        return{
            info:'',
            tableData:[
{
"brand":"奥迪",
"value":[{
        "brand":"奥迪",
        "series":"A4L",
        "partName":"A4L-B8双氙前照灯(单只)",
        "name":"双氙大灯",
        "no":"B8",
        "model":"09-12款",
        "oe":"8K0941030G",
        "price4s":"11597",
        "price":"3479",
        "less":"600",
        "lessPrice":"2879",
        "getTime":"9月27日-10月31日",
        "expiretime":"10月1日-11月30日",
        "url":"http://coupon.m.jd.com/coupons/show.action?key=22750564ca3b4d38b71d3c0160fd8b94&roleId=60183767&to=mall.jd.com/index-11478943.html"
    },
    {
        "brand":"奥迪",
        "series":"A4L",
        "partName":"A4L-B9LED前照灯(单只)",
        "name":"LED大灯",
        "no":"B9",
        "model":"17-19款",
        "oe":"8WD941034",
        "price4s":"29815",
        "price":"8945",
        "less":"2000",
        "lessPrice":"6945",
        "getTime":"9月27日-10月31日",
        "expiretime":"10月1日-11月30日",
        "url":"http://coupon.m.jd.com/coupons/show.action?key=a3c60523caaa4b98bdd6be69e0b8f2b2&roleId=60184580&to=mall.jd.com/index-11478943.html"
    },
    {
        "brand":"奥迪",
        "series":"A6",
        "partName":"C6-09双氙前照灯(单只)",
        "name":"双氙大灯",
        "no":"C6",
        "model":"09-11款",
        "oe":"4F0941030ES",
        "price4s":"11240",
        "price":"3372",
        "less":"1000",
        "lessPrice":"2372",
        "getTime":"9月27日-10月31日",
        "expiretime":"10月1日-11月30日",
        "url":"http://coupon.m.jd.com/coupons/show.action?key=b644cef707ef497caf35892e01b7b04d&roleId=60184888&to=mall.jd.com/index-11478943.html"
    },
    {
        "brand":"奥迪",
        "series":"Q5",
        "partName":"Q5-08款前大灯 (单只)",
        "name":"大灯",
        "no":"Q5",
        "model":"08-12年",
        "oe":"8RD941004A",
        "price4s":"7983",
        "price":"2954",
        "less":"300",
        "lessPrice":"2654",
        "getTime":"9月27日-10月31日",
        "expiretime":"10月1日-11月30日",
        "url":"http://coupon.m.jd.com/coupons/show.action?key=238d663fa5e241b28055c8e476d7ad54&roleId=60186064&to=mall.jd.com/index-11478943.html"
    },
    {
        "brand":"奥迪",
        "series":"Q5",
        "partName":"Q5PA-13款前大灯 (单只)",
        "name":"大灯",
        "no":"Q5PA",
        "model":"13-18年",
        "oe":"8RD941006",
        "price4s":"11548",
        "price":"3464",
        "less":"600",
        "lessPrice":"2864",
        "getTime":"9月27日-10月31日",
        "expiretime":"10月1日-11月30日",
        "url":"http://coupon.m.jd.com/coupons/show.action?key=c4914f5e43a94532aaa5fb9c57fc45e8&roleId=60186193&to=mall.jd.com/index-11478943.html"
    }]
},{
"brand":"奔驰",
"value":[
{
        "brand":"奔驰",
        "series":"C级",
        "partName":"C级15款低配前大灯 (单只)",
        "name":"大灯",
        "no":"W205",
        "model":"15-18年",
        "oe":"2059067403",
        "price4s":"15017",
        "price":"4505",
        "less":"2000",
        "lessPrice":"2505",
        "getTime":"9月27日-10月31日",
        "expiretime":"10月1日-11月30日",
        "url":"http://coupon.m.jd.com/coupons/show.action?key=9cb3c618fc9640d6b0bbd71939529edc&roleId=60186455&to=mall.jd.com/index-11478943.html"
    },
    {
        "brand":"奔驰",
        "series":"E级",
        "partName":"E级16款低配前大灯 (单只)",
        "name":"大灯",
        "no":"W213",
        "model":"16-19年",
        "oe":"2139066601",
        "price4s":"17591",
        "price":"5277",
        "less":"2000",
        "lessPrice":"3277",
        "getTime":"9月27日-10月31日",
        "expiretime":"10月1日-11月30日",
        "url":"http://coupon.m.jd.com/coupons/show.action?key=9cb3c618fc9640d6b0bbd71939529edc&roleId=60186455&to=mall.jd.com/index-11478943.html"
    }
]
},{
"brand":"保时捷",
"value":[ {
        "brand":"保时捷",
        "series":"卡宴",
        "partName":"卡宴11-14款大灯低配（不随动）(单只)",
        "name":"大灯中配",
        "no":"958",
        "model":"11-14年",
        "oe":"95863117600",
        "price4s":"6261",
        "price":"3569",
        "less":"300",
        "lessPrice":"3269",
        "getTime":"9月27日-10月31日",
        "expiretime":"10月1日-11月30日",
        "url":"http://coupon.m.jd.com/coupons/show.action?key=b524597ff2234ab0a51f58ae346840e3&roleId=60186769&to=mall.jd.com/index-11478943.html"
    },
    {
        "brand":"保时捷",
        "series":"卡宴",
        "partName":"卡宴11-14款大灯GTS (单只)",
        "name":"GTS大灯",
        "no":"958",
        "model":"11-14年",
        "oe":"95863118200",
        "price4s":"15091",
        "price":"5886",
        "less":"300",
        "lessPrice":"5586",
        "getTime":"9月27日-10月31日",
        "expiretime":"10月1日-11月30日",
        "url":"http://coupon.m.jd.com/coupons/show.action?key=b524597ff2234ab0a51f58ae346840e3&roleId=60186769&to=mall.jd.com/index-11478943.html"
    },
    {
        "brand":"保时捷",
        "series":"卡宴",
        "partName":"卡宴15-17款大灯中配（随动）(单只)",
        "name":"大灯中配",
        "no":"958",
        "model":"15-17年",
        "oe":"95863117830",
        "price4s":"12841",
        "price":"5393",
        "less":"300",
        "lessPrice":"5093",
        "getTime":"9月27日-10月31日",
        "expiretime":"10月1日-11月30日",
        "url":"http://coupon.m.jd.com/coupons/show.action?key=b524597ff2234ab0a51f58ae346840e3&roleId=60186769&to=mall.jd.com/index-11478943.html"
    },
    {
        "brand":"保时捷",
        "series":"卡宴",
        "partName":"卡宴15-17款大灯低配（不随动）(单只)",
        "name":"大灯高配（随动）",
        "no":"958",
        "model":"15-17年",
        "oe":"95863117630",
        "price4s":"7930",
        "price":"4282",
        "less":"300",
        "lessPrice":"3982",
        "getTime":"9月27日-10月31日",
        "expiretime":"10月1日-11月30日",
        "url":"http://coupon.m.jd.com/coupons/show.action?key=b524597ff2234ab0a51f58ae346840e3&roleId=60186769&to=mall.jd.com/index-11478943.html"
    },
    {
        "brand":"保时捷",
        "series":"卡宴",
        "partName":"卡宴18-20款大灯低配（不随动）(单只)",
        "name":"大灯中配",
        "no":"9Y0",
        "model":"18-20年",
        "oe":"9Y0941032Q",
        "price4s":"9500",
        "price":"7030",
        "less":"600",
        "lessPrice":"6430",
        "getTime":"9月27日-10月31日",
        "expiretime":"10月1日-11月30日",
        "url":"http://coupon.m.jd.com/coupons/show.action?key=c584497c3f9d45c48c4588db554947ca&roleId=60186997&to=mall.jd.com/index-11478943.html"
    },
    {
        "brand":"保时捷",
        "series":"卡宴",
        "partName":"卡宴18-20款大灯中配（随动）(单只)",
        "name":"大灯高配（随动）",
        "no":"9Y0",
        "model":"18-20年",
        "oe":"9Y0941034T",
        "price4s":"12824",
        "price":"8207",
        "less":"1000",
        "lessPrice":"7207",
        "getTime":"9月27日-10月31日",
        "expiretime":"10月1日-11月30日",
        "url":"http://coupon.m.jd.com/coupons/show.action?key=6c112ad347774e4aa4e6cb5b7806f3dc&roleId=60187117&to=mall.jd.com/index-11478943.html"
    }]
}]
        }
    },
    methods:{
        tart_url(url){
            if(this.info.status!=1){
                this.$dialog.alert({
                    message: '请加盟！',
                    theme: 'round-button',
                }).then(() => {
                    wx.closeWindow();
                });
            }
            window.location.href=url;
        }
    },
    created(){
        this.info =  userInfo.get();
    }
}
</script>

<style scoped>
.title_nav{
    width: 100vw;
    overflow: hidden;
}
hr{
    margin: 0px;
    border: none;
	height: 2px;
	background-color: #4600af;
    margin-left: 2%;
}
.liji_div{
    background-color:#4600af;
    color: white;
    padding: 2px 4px;
    border-radius: 4px;
    font-weight: bold;
}
.arrow_div{
    width: 100%;
    position: absolute;
    bottom: 20px;
}
.right_price{
    font-size: 12px;
}
.quanhou_div{
    text-align: right;
    display: inline-block;
    font-size: 12px;
    position:absolute;
    bottom: 20px;
}
.quanhou_div div{
    display: inline-block;
}
.arrow{
position: relative;
height: 80%;
}
.item_name{
    font-size: 18px;
    font-weight:bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.item_daihao,.item_price{
    font-size: 14px;
}
.item_price{
    height: 50px;
    line-height: 50px;
}
.nav_div{
    width: 100%;
}
.nav_div img{
    width: 100%;
    
}
.item_div{
    width: 100%;
    color: #4600af;
    height: 100px;
    margin-top: 20px;
}
.item_left{
    padding-left: 10px;
    padding-top:10px;
    width: 58%;
    float: left;
    height: 100%;
}
.item_right{
    padding-top: 10px;
    width: 38%;
    display: inline-block;
    height: 100%;
}
</style>